//For a new demo, need to update values here, and also the primary and accent palettes in tailwind.config.js
export const demo = {
  partnerName: 'Mastercard',
  assetsLogoPath: 'assets/images/logo/',
  partnerFaviconAlt: 'Mastercard Logo',
  partnerFaviconLogo: 'mastercard-brandmark.png', //browser tab and loading logo (transparent bg)
  partnerLightLogo: 'mastercard.png', //light mode logo (dark text, transparent bg)
  partnerDarkLogo: 'mastercard_docs-darkmode.png', //dark mode logo (light text, transparent bg)
};

document.title = demo.partnerName;
document.getElementById('logoImg').setAttribute('alt', demo.partnerFaviconAlt);
document
  .getElementById('logoImg')
  .setAttribute('src', demo.assetsLogoPath + demo.partnerFaviconLogo);
document
  .getElementById('favicon')
  .setAttribute('href', demo.assetsLogoPath + demo.partnerFaviconLogo);
